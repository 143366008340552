<template>
  <div class="total-card" v-if="card">
    <div class="total-card-content" :style="`--color:${card.color ? card.color : '#e7e7e7'}`">
      <div class="total-card-left">
        <div class="total-card-icon" :style="`--iconSize:${card.iconSize || '200%'}`">
          <figure class="figure"><iconic :name="card.icon" /></figure>
        </div>
        <div class="total-card-info">
          <div class="total-card-info-content">
            <div class="total-card-info-label">{{ card.label }}</div>
            <div class="total-card-info-desc">{{ card.desc }}</div>
            <ProgressBar v-if="card.progress" height="8px" :color="card.color || '#e7e7e7'" :percent="card.progress" />
          </div>
        </div>
      </div>
      <div class="total-card-right">
        <div>
          <span class="sign">{{ card.sign }}</span>
          <span class="mount">{{ number_format(card.mount) }}</span>
        </div>
        <div v-if="card.mount_label">
          <span class="mount_label">{{ card.mount_label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card"],
  components: {
    ProgressBar: () => import("../widgets/ProgressBar.vue"),
  },
};
</script>
